<template>
  <div>
    <b-row class="container galleryCenter">
      <template v-if='isMobile(true)'>
        <b-col class="noPadLeft">
          <h2 class="SectionTitle noGap">Image Gallery</h2>
          <h2 class="SectionTitle_Mob">{{gallery.event_name}}</h2>
        </b-col>   
      </template>
      <template v-else>
        <b-col class="noPadLeft">
          <h2 class="SectionTitle" :class="{'adtcolour' : develop == 'adt'}">Image Gallery</h2>
        </b-col>
        <b-col>
          <h2 class="SectionTitle rightAligned" :class="{'adtcolour' : develop == 'adt'}">{{gallery.event_name}}</h2>
        </b-col>
      </template>
    </b-row>
      <template v-if='isMobile(true)'>
        <div class="GalleryWidth">
          <b-carousel
            id="carousel-1"
            v-model="slides"
            :interval="15000"
            controls
            img-width="1024"
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <!-- Slides with image only -->
            <b-carousel-slide v-for="(slide, index) in objSplit.slice(0,8)"
            :key="index">
              <template #img>
                <div class="galleryBkImage" v-bind:style="{ 'background-image': 'url(' + slide.count.url + ')' }"></div>
              </template>
            </b-carousel-slide>
          </b-carousel>

        </div>
      </template>
      <template v-else>
        <div class="container">
          <b-modal id="showImageModal" size="xl">
            <b-img-lazy class="modalUpdate" :src="modal_image_path" />
          </b-modal>
          <div class="">
            <b-row class="galleryImageRow">
              <b-col lg="3" class="galleryCenter galleryImages galleryNew" v-for="image in objSplit.slice(0, 8)" :key="image.count.ID" v-bind:style="{ 'background-image': 'url(' + image.count.url + ')' }" @click="open_modal(image.count.url)">
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "gallery",
  data() {
    return {
      gallery: [],
      galleryInside: [],
      modal_image_path: "",
    };
  },
  props: [
    'develop'
  ],
  computed: {
    objSplit() {
      var array = this.gallery;
      var result = Object.keys(array).map((e) => ({
        word: e,
        count: array[e],
      }));
      return result;
    },
      objSplitInside() {
      var array = this.gallery;
      var result = Object.keys(array).map((e) => ({
        word: e,
        count: array[e],
      }));
      return result;
    },
  },
  methods : {
    open_modal(image_path) {
      this.modal_image_path = image_path
      this.$bvModal.show('showImageModal')
    },
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/AlbumGallery?_embed?randomadd=" + new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.gallery = response.data[0].acf;
        this.galleryInside = response.data[0].acf
      });
  },
};
</script>

<style scoped>
::v-deep .modal-header {
  display: none!important;
}
.modalUpdate {
  width: 100%;
}
.galleryImageRow {
  margin: auto;
}
.galleryImages.galleryNew.col-lg-3 {
  height: 261px;
  display: block;
  padding: 0;
  margin: 0px;
  background-size: cover;
  background-position: center;
  width: 300px;
  margin-bottom: 25px;
  margin-left: 15px;
  margin-right: 15px;
  flex: 0 0 22%;
  max-width: 22%;
}
img.imageSize {
  width: 100%;
}
.insideImage {
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  background-position: top;
}
::v-deep .adtcolor {
  color: #76c3bd!important;
}
h2.SectionTitle.rightAligned.adtcolour {
  color: #76c3bd!important;
}
h2.SectionTitle.adtcolour {
  color: #76c3bd!important;
}
button.btnSlider {
  border-radius: 0px;
  width: 200px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #042F62;
  margin: auto;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
}
button.btnSlider:hover {
  border-radius: 0px;
  width: 200px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #41a2f6;
  margin: auto;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
}
::v-deep .modal-open > #one___BV_modal_outer_ > .modal-backdrop{
  /* background-color: pink!important; */
}
body .modal-backdrop.fade.in {
    z-index: 0 !important;
}
::v-deep .modal-backdrop {
  background-color: pink!important;
  opacity: 0!important;
}
::v-deep .backgroundSection {
  background-color: pink!important;
}
::v-deep .modal {
  background-color:rgb(192, 187, 188,0.5)!important;
}
::v-deep .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - -12rem);
}
div#one {
  transition: opacity 0.15s linear;
  /* background: pink; */
  background-color: rgba(82, 82, 82, 0.8)!important;
}
dialog {
  /* width: 75%;
  height: 700px; */
  width: 72%;
  padding: 50px;
}
::v-deep dialog > div#carousel-1 > a.carousel-control-prev {
  left: -113px!important;
}
::v-deep dialog > div#carousel-1 > a.carousel-control-next {
  right: -113px!important;
}
.insideSlider {
  width: 100%;
}
::backdrop {
  transition: opacity 0.15s linear;
  /* background: pink; */
  background-color: rgba(82, 82, 82, 0.8)!important;
}
::v-deep a.carousel-control-prev:hover {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1!important;
  transition: opacity 0.15s ease;
}
::v-deep a.carousel-control-prev {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5!important;
  transition: opacity 0.15s ease;
}
::v-deep a.carousel-control-next:hover {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1!important;
  transition: opacity 0.15s ease;
}
::v-deep a.carousel-control-next {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5!important;
  transition: opacity 0.15s ease;
}

::v-deep .carousel-control-next {
  right: -100px;
  top: -40px;
}
::v-deep .carousel-control-prev {
  right: -100px;
  top: -40px!important;
}
::v-deep .carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 12px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  /* opacity: 0.5 !important; */
}
::v-deep .carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 12px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  /* opacity: 0.5 !important; */
}
::v-deep .carousel-control-next-icon {
  background-image: url('https://assets.asiantour.com/asian-tour/2024/04/Icon-awesome-chevron-downDARKGREY.png');
}
::v-deep .carousel-control-prev-icon {
  background-image: url('https://assets.asiantour.com/asian-tour/2024/04/Icon-awesome-chevron-LEFT_DARKGREY.png');
}
::v-deep .galleryBkImage {
  width: 390px!important;
  height: 271px!important;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .GalleryWidth {
  padding-left: 10px;
  padding-right: 10px;
  height: 250px;
}
::v-deep .carousel-control-next {
  right: -100px;
}
::v-deep .carousel-control-prev {
  left: -100px;
}
::v-deep .carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1!important;
  transition: opacity 0.15s ease;
}
::v-deep .carousel-item {
  position: relative;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  padding-left: 5px;
  padding-right: 5px;
  height: 743px;
}
h2.SectionTitle.rightAligned {
  text-align: right;
}
.noPadLeft {
  padding-left: 0;
}
.SectionTitle {
  font-size: 23px;
  font-weight: 500;
  color: #042F62;
  padding-bottom: 25px;
  padding-left: 8px;
}
.SectionTitle_Mob {
  font-size: 19px;
  font-weight: 500;
  color: #042F62;
  padding-bottom: 25px;
}
h2.SectionTitle.noGap {
  padding-bottom: 0;
}
.galleryCenter {
  margin: auto;
}
::v-deep .card {
  border-radius: 0px!important;
}
::v-deep .card-img, .card-img-top {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.nopad {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
}
.card-body {
  display: none;
}
::v-deep .carousel-caption {
  right: 0%;
  left: 5px;
}
.card-img-top {
  /* border-radius: 10px; */
  object-fit: cover;
  object-position: top center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.title {
  color: #133f7b;
}
.card {
  height: 300px;
  width: 100%;
  background-color: #f1f1f1;
  border: none;
}
.modelImg {
  position: absolute;
  margin: -87% -50%;
  width: 200%;
  background: white;
  padding: 40px;
}
.mobile {
  display: none;
}
@media (min-width: 576px) {
    ::v-deep .modal-dialog {
        /* max-width: 570px!important; */
        margin: 1.75rem auto;
    }
}
@media only screen and (max-width: 1200px) {
  .card {
   height: 260px;
  }
}


@media only screen and (max-width: 990px) {
  .SectionTitle {
    padding-left: 0px!important;
  }
  ::v-deep .galleryImages {
    padding-left: 0px;
    padding-right: 0px;
  }
  ::v-deep .nopad.col-sm-12.col-lg-3 > .card > img {
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  ::v-deep .img-fluid{
    width: 395px!important;
    height: 227px!important;
  }
  .title{
    padding-bottom: 20px;
  }
  ::v-deep .img-fluid {
    height: 300px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .carousel-caption {
    bottom: -120px;
  }
  ::v-deep .carousel-control-prev {
    display: none;
  }
  ::v-deep .carousel-control-next {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .carousel-caption {
    bottom: -190px;
  }
  ::v-deep .carousel-item {
    position: relative;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
    padding-left: 5px;
    padding-right: 5px;
    height: auto!important;
  }
}
</style>
